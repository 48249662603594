.action {
    margin-left: 5px;
    transition: 50ms;
}

.action:hover {
    text-decoration: underline;
}

.action-bar {
    margin: 5px;
}

.action-item {
    display: inline;
    padding: 10px;
}